/*
 * action types
 */
export const SET_PLAYING = "SET_PLAYING";
export const TOGGLE_PLAYING = "TOGGLE_PLAYING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_FILE = "SET_FILE";
export const SET_PROGRESS = "SET_PROGRESS";
export const SET_VOLUME = "SET_VOLUME";
export const SET_CURRENTLY_PLAYING = "SET_CURRENTLY_PLAYING";
export const SET_DURATION = "SET_DURATION";
export const TOGGLE_MUTE = "TOGGLE_MUTE";
export const MARK_READY = "MARK_READY";
export const TOGGLE_CURRENTLY_PLAYING_LIKE_STATE =
  "TOGGLE_CURRENTLY_PLAYING_LIKE_STATE";
export const SET_PLAYER_REF = "SET_PLAYER_REF";
export const SET_QUEUE = "SET_QUEUE";
export const INCREMENT_CURRENT_TRACK_INDEX = "INCREMENT_CURRENT_TRACK_INDEX";
export const DENCREMENT_CURRENT_TRACK_INDEX = "DENCREMENT_CURRENT_TRACK_INDEX";
export const SET_NEXT_UP = "SET_NEXT_UP";
export const CONCAT_NEXT_UP = "CONCAT_NEXT_UP";
export const SET_LOADING_MORE_NEXT_UP = "SET_LOADING_MORE_NEXT_UP";
export const INSERT_NEW_TRACK = "INSERT_NEW_TRACK";
export const UPDATE_NEW_TRACK = "UPDATE_NEW_TRACK";
export const REMOVE_NEW_TRACK = "REMOVE_NEW_TRACK";
export const SHIFT_NEW_TRACK = "SHIFT_NEW_TRACK";
export const RESET_NEW_UPLOADED_TRACKS = "RESET_NEW_UPLOADED_TRACKS";
export const SET_UTM_SOURCE = "SET_UTM_SOURCE"
export const PLAY = "PLAY"
export const PAUSE = "PAUSE"
/*
 * action creators
 */
export default {
  setLoadingMoreNextUp: loadingMoreNextUp => ({
    type: SET_LOADING_MORE_NEXT_UP,
    loadingMoreNextUp,
  }),
  setPlay: videoRef => ({ type:PLAY,videoRef }),
  setPause: videoRef => ({ type:PAUSE,videoRef}),
  setNextUp: next_up => ({ type: SET_NEXT_UP, next_up }),
  concatNextUp: next_up => ({ type: CONCAT_NEXT_UP, next_up }),
  setPlaying: playing => ({ type: SET_PLAYING, playing }),
  setProgress: progress => ({ type: SET_PROGRESS, progress }),
  setCurrentUser: (current_user, meta) => ({
    type: SET_CURRENT_USER,
    current_user,
    unread_notifications_count: meta && meta.unread_notifications_count,
  }),
  setQueue: queue => ({ type: SET_QUEUE, queue }),
  setDuration: duration => ({ type: SET_DURATION, duration }),
  markReady: () => ({ type: MARK_READY }),
  setVolume: percentile => ({ type: SET_VOLUME, percentile }),
  toggleMute: () => ({ type: TOGGLE_MUTE }),
  setFile: file => ({ file, type: SET_FILE }),
  setCurrentlyPlaying: currently_playing => ({
    type: SET_CURRENTLY_PLAYING,
    currently_playing,
  }),
  toggleCurrentlyPlayingLikeState: () => ({
    type: TOGGLE_CURRENTLY_PLAYING_LIKE_STATE,
  }),
  setPlayerRef: player => ({ type: SET_PLAYER_REF, player }),
  // queue
  incrementCurrentTrackIndex: () => ({ type: INCREMENT_CURRENT_TRACK_INDEX }),
  decrementCurrentTrackIndex: () => ({ type: DENCREMENT_CURRENT_TRACK_INDEX }),
  insertNewTrack: newTrack => ({ type: INSERT_NEW_TRACK, newTrack }),
  resetNewUploadedTracks: () => ({ type: RESET_NEW_UPLOADED_TRACKS }),
  updateNewTrack: updatedNewTrack => ({
    type: UPDATE_NEW_TRACK,
    updatedNewTrack,
  }),
  removeNewTrack: newTrack => ({ type: REMOVE_NEW_TRACK, newTrack }),
  shiftNewTrack: (from, to) => ({
    type: SHIFT_NEW_TRACK,
    from,
    to,
  }),
  setUtmSource: utm_source => ({ type: SET_UTM_SOURCE, utm_source }),
};
