import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as NewsfeedIcon } from "../../../public/icons/NavMenu-icons/newsfeed_icon.svg";
import { ReactComponent as ProfileIcon } from "../../../public/shared/avatar-default.svg";
import { ReactComponent as LogoutIcon } from "../../../public/shared/logout.svg";
import { logout } from "../../../api/authentication";

import logo from "./images/logo.svg";
import logo_animated from "./images/logo_white.gif";

const LandingHeader = (props) => {
  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const [isHeaderSticky, setHeaderSticky] = useState(false);

  const handleScroll = (e) => {
    setTimeout(function () {
      const header = document.getElementById("header");
      const isSticky = window.pageYOffset >= 20;
      setHeaderSticky(isSticky);
      if (isSticky) {
        header?.classList.add("scrollUp");
        header?.classList.add("fixed");
        header?.classList.add("is-sticky-open");
      } else {
        header?.classList.remove("scrollUp");
        header?.classList.remove("fixed");
        header?.classList.remove("is-sticky-open");
      }
    }, 200);
    setTimeout(function () {
      const header = document.getElementById("header");
      if (window.pageYOffset + 250 >= window.innerHeight) {
        header?.classList.add("fixed");
      } else {
        header?.classList.remove("fixed");
      }
    }, 210);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (mobileNavbarOpen) {
      rootElement?.classList.add("overflow-hidden");
    } else {
      rootElement?.classList.remove("overflow-hidden");
    }
  }, [mobileNavbarOpen]);

  const toggleNavbar = () => setMobileNavbarOpen((prev) => !prev);

  return (
    <header id="header">
      <div className="headerInner">
        <div className="logo">
          <Link to="/">
            <img src={isHeaderSticky ? logo_animated : logo} alt="" />
          </Link>
        </div>
        <div className="navigationOuter">
          <div className={`menuIcon ${mobileNavbarOpen ? "active" : ""}`} onClick={toggleNavbar}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={`navigationInner ${mobileNavbarOpen ? "navToggle" : ""}`}>
            {props.current_user ? (
              <ul>
                <li>
                  <Link to="/" onClick={toggleNavbar}>
                    Newsfeed
                    <div className="navHover">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to={`/${props.current_user.slug}`} onClick={toggleNavbar}>
                    My profile
                    <div className="navHover">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/settings" onClick={toggleNavbar}>
                    SETTINGS
                    <div className="navHover">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={logout}>
                    Logout
                    <div className="navHover">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </Link>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <Link to="/login" onClick={toggleNavbar}>
                    Login
                    <div className="navHover">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/signup" onClick={toggleNavbar}>
                    Sign Up
                    <div className="navHover">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/about" onClick={toggleNavbar}>
                    About Us
                    <div className="navHover">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </Link>
                </li>
                <li>
                  <a href="mailto:help@unifimusic.ai">
                    Contact Us
                    <div className="navHover">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingHeader;
