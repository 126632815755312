import Axios from "./index";

export const login = (loginParams) => {
  return Axios.post("/user_sessions", loginParams);
};

export const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = "/";
};

export const authorizeClientId = (client_id) => {
  return Axios.get(`/oauth/authorize?client_id=${client_id}`);
};
