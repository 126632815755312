import { combineReducers } from "redux";
import playing from "./playing";
import current_user from "./current_user";
import currently_playing from "./currently_playing";
import progress from "./progress";
import volume from "./volume";
import next_up from "./next_up";
import file from "./file";
import player_ref from "./player_ref";
import queue from "./queue";
import loading_more_next_up from "./loading_more_next_up";
import can_load_more_next_up from "./can_load_more_next_up";
import new_tracks from "./new_tracks";
import utm_source from "./utm_source";
import videoPlaying from "./videoPlaying";

export default combineReducers({
  can_load_more_next_up,
  loading_more_next_up,
  file,
  playing,
  current_user,
  progress,
  currently_playing,
  volume,
  next_up,
  player_ref,
  queue,
  new_tracks,
  utm_source,
  videoPlaying,
});
