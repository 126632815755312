import axios from "axios";
import version from "./../version.json";

let Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    BUILDNUMBER: version,
    OS: "web",
  },
  responseType: "json",
  contentType: "json",
});
Axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getAuthorizationToken();
    return config;
  },
  (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
  (response) => {
    if (response.data && response.data.guest_token)
      sessionStorage.setItem("Authorization", response.data.guest_token);
    if (response.data && response.data.user_token) {
      if (response.data.remember_me) {
        localStorage.setItem("Authorization", response.data.user_token);
        localStorage.setItem("sb_token", response.data.sb_token);
      } else {
        sessionStorage.setItem("Authorization", response.data.user_token);
        sessionStorage.setItem("sb_token", response.data.sb_token);
      }
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      onUnauthorizedActionAttempt();
    }
    log_error(error);
    return Promise.reject(error);
  }
);

axios.get("https://ipapi.co/json").then(({ data }) => {
  Axios.defaults.headers["City"] = data.city;
  Axios.defaults.headers["State"] = data.region;
  Axios.defaults.headers["Country"] = data.country_code_iso3;
});

export default Axios;

export const log_error = (e) => {
  if (
    e.response &&
    e.response.data &&
    e.response.data.errors &&
    Array.isArray(e.response.data.errors)
  ) {
    console.warn(
      `${e.response.status}:${e.response.statusText} ${JSON.stringify(
        e.response.data.errors
      )}`
    );
    return e.response.data.errors;
  } else if (e.message) {
    return [{ message: e.message }];
  } else {
    console.warn("HV_ERRORS :: ", e.message);
    return [{ message: "Unknown Error" }];
  }
};

let callbacks = [];

export function addUnauthorizedCallback(callback) {
  callbacks.push(callback);
}

export function onUnauthorizedActionAttempt() {
  callbacks.forEach((callback) => {
    callback();
  });
}

export function getAuthorizationToken() {
  return (
    localStorage.getItem("Authorization") ||
    sessionStorage.getItem("Authorization")
  );
}
